<template>
    <div style="height:100%">
        <div class="page-header">工厂聚集区字典</div>
        <div style="margin:20px;text-align:right;">
            <el-button @click="add()" size="small" type="primary">增加聚集区</el-button>
        </div>
        <div class="block" style="height: calc(100% - 155px)">
            <div class="list" style="height: calc(100% - 60px);overflow-y: auto">
                <el-table :data="list" style="width: 100%">
                    <el-table-column prop="id" label="ID" width="120">
                    </el-table-column>
                    <el-table-column prop="name" label="地区名称">
                    </el-table-column>
                    <!-- <el-table-column prop="name" label="状态">
                        <template slot-scope="scope">
                            <div v-if="scope.row.stat==1">启用</div>
                            <div v-if="scope.row.stat==2">禁用</div>
                        </template>
                    </el-table-column> -->
                    <el-table-column prop="address" label="操作" width="120">
                        <template slot-scope="scope">
                            <el-button type="text" size="small" @click="edit(scope.row,scope.$index)">
                                <span class="icon iconfont icon-bianji1"></span>&nbsp;编辑
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <!-- <div style="padding:12px">
                <el-pagination background layout="prev, pager, next" :current-page.sync="queryOpt.pageNumber" :page-size="queryOpt.pageCount" :total="total"></el-pagination>
            </div> -->
        </div>

        <el-dialog :title="popTitle" :visible.sync="popVisible" :destroy-on-close="true">
            <EditZone :form="ruleForm" :popTitle="popTitle" @hide="hide"></EditZone>
        </el-dialog>
    </div>
</template>
<script>
    import { getFactoryZones } from '@/service/dictionary';
    import EditZone from './EditZone.vue';
    export default {
        components: {
            EditZone,
        },
        data() {
            return {
                popVisible: false,
                popTitle: '',
                queryOpt:{
                    page:1,
                    pagesize:15,
                },
                list:[],
                ruleForm:"",
            }
        },
        created() {
            this.refreshItems();
        },
        mounted() {},
        methods: {
            refreshItems() {
                console.log('refreshItems');
                getFactoryZones().then(rst => {
                    this.list = rst;
                    console.log(this.list);
                }).catch(err => {
                    console.log(err);
                    this.$message.error(err.message);
                });
            },
            add(item,index) {
                this.popTitle = "新增工厂聚集区";
                this.popVisible = true;
                this.ruleForm = {
                    name:null,
                }
            },
            edit(item,index) {
                this.popTitle = "修改工厂聚集区";
                this.popVisible = true;
                this.ruleForm = {
                    id:item.id,
                    name:item.name,
                    // stat:item.stat,
                }
            },
            hide() {
                this.popVisible=false;
                this.refreshItems();
            }
        }
    }
</script>
<style scoped src="../../style.css"></style>
<style scoped>
    .icon {
        font-size: 13px;
    }
    /*----选项卡----*/
    .tab {
        padding:0 20px;
    }
    .tab_menu {
        /* min-width: 460px; */
        /* background: #fff;
        padding: 0 20px;
        margin: 20px; */
    }
    .tab_menu .menu_item{
        display: inline-block;
        vertical-align: top;
        width: 85px;
        height: 55px;
        text-align: center;
        line-height: 55px;
        margin-right:30px;
        cursor: pointer;
        font-size: 14px;
        color: #808080;
    }
    .tab_menu .menu_item.cur{
        color: #4D4D4D;
        border-bottom: 2px solid #4D4D4D;
    }
</style>