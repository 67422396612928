<template>
    <div style="max-width: 300px;margin: 20px auto;">
        <el-form :model="ruleForm" :rules="rules" ref="formCheck" label-width="80px" class="login-form">
            <el-form-item prop="name" label="标签名称">
                <el-input placeholder="请输入标签名称" v-model="ruleForm.name"></el-input>
            </el-form-item>
            <!-- <el-form-item v-if="ruleForm.stat" label="标签状态">
                <el-radio-group v-model="ruleForm.stat">
                    <el-radio :label="1">启用</el-radio>
                    <el-radio :label="2">禁用</el-radio>
                </el-radio-group>
            </el-form-item> -->
            <div class="flex flex-justify-around" style="padding-top:30px;">
                <div><el-button @click="hide()" style="width: 80px;" type="primary" plain>取消</el-button></div>
                <div><el-button @click="submitForm()" style="width: 80px;" type="primary">提交</el-button></div>
            </div>
        </el-form>
    </div>
</template>
<script>
    import { addFactoryZones,editFactoryZones } from '@/service/dictionary';
    export default {
        props: ['form','popTitle'],
        data() {
            return {
                rules: {
                    name: [
                        { required: true, message: '请输入标签名称', trigger: 'blur' }
                    ],
                },
                ruleForm:{
                    name:null,
                    // tag_type:null,
                    // stat:null,
                },
            }
        },
        watch:{
            form:{
                handler() {
                    this.ruleForm = this.form;
                },
                deep: true
            }
        },
        created() {
            this.ruleForm = this.form;
            console.log(this.ruleForm);
        },
        mounted() {},
        methods: {
            hide() {
                this.$emit("hide");
            },
            submitForm() {
                this.$refs['formCheck'].validate((valid) => {
                    if (valid) {
                        this.submit();
                    }
                });
            },
            submit() {
                if (this.popTitle.slice(0,2) == "新增") {
                    addFactoryZones(this.ruleForm).then(rst => {
                        this.$emit("refreshItems");
                        this.$emit("hide");
                        this.$message.success("添加成功");
                    }).catch(err => {
                        this.$message.error(err.message);
                    });
                } else if (this.popTitle.slice(0,2) == "修改") {
                    editFactoryZones(this.ruleForm).then(rst => {
                        this.$emit("refreshItems");
                        this.$emit("hide");
                        this.$message.success("修改成功");
                    }).catch(err => {
                        this.$message.error(err.message);
                    });
                }
            },
        }
    }
</script>
<style scoped>
    
</style>